import { render, staticRenderFns } from "./globalWindowRight.vue?vue&type=template&id=ed49ad52&scoped=true"
import script from "./globalWindowRight.vue?vue&type=script&lang=js"
export * from "./globalWindowRight.vue?vue&type=script&lang=js"
import style0 from "./globalWindowRight.vue?vue&type=style&index=0&id=ed49ad52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed49ad52",
  null
  
)

export default component.exports