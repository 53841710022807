<template>
    <div class="global-wrap" :style="viewStyle">
        <div class="global-wrap__content">
            <div class="global-wrap__content__right" @click="rightHandle" :style="rightDivStyle">
                <el-image :style="rightImageStyle" :src="require('@/assets/right.png')" fit="contain"> </el-image>
            </div>
            <transition name="slide-x">
                <div class="global-wrap__content__box" v-if="isShow" key="contact-box">
                    <div class="global-wrap__content__box__item" @click="upHandle" v-if="!isAtTop">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/up.png')" fit="contain"> </el-image>
                    </div>
                    <div class="global-wrap__content__box__item" @click="chatHandle">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/chat.png')" fit="contain"> </el-image>
                    </div>
                    <div class="global-wrap__content__box__item tel-qjp" @mouseenter="isTel = true" @mouseleave="isTel = false">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/tel.png')" fit="contain"> </el-image>
                        <div class="global-wrap__content__box__item__text" v-if="isTel">+86 02558808446</div>
                    </div>
                    <div class="global-wrap__content__box__item email-qjp" @mouseenter="isEmail = true" @mouseleave="isEmail = false">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/email.png')" fit="contain"> </el-image>
                        <div class="global-wrap__content__box__item__text" v-if="isEmail">info@soppv.com</div>
                    </div>
                    <div class="global-wrap__content__box__item phone-qjp" @mouseenter="isPhone = true" @mouseleave="isPhone = false">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/phone.png')" fit="contain"> </el-image>
                        <div class="global-wrap__content__box__item__text" v-if="isPhone">+86 13914479750</div>
                    </div>
                    <div class="global-wrap__content__box__item qrcode-qjp" @mouseenter="isQrcode = true" @mouseleave="isQrcode = false">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/qrcode.png')" fit="contain"> </el-image>
                        <div class="global-wrap__content__box__item__text" v-if="isQrcode" style="width: 156px; height: 150px">
                            <div class="label-text">whatsApp</div>
                            <el-image style="width: 100px; height: 100px" :src="require('@/assets/whatsApp.png')" fit="contain"> </el-image>
                        </div>
                    </div>
                    <div class="global-wrap__content__box__item" @click="downHandle">
                        <el-image style="width: 20px; height: 20px" :src="require('@/assets/down.png')" fit="contain"> </el-image>
                    </div>
                </div>
            </transition>
        </div>
        <el-dialog :visible.sync="dialogVisible" :class="[isMobile?'active-dialog':'']" :title="$lang('common.contact_us')">
            <div style="margin-bottom: 20px">{{ $lang("common.dialog__text") }} {{ isMobile }}</div>
            <div class="elRofm" style="">
                <el-form :model="formData" :rules="messageRules" ref="ruleForm">
                    <el-form-item prop="name">
                        <el-input v-model="formData.name" :placeholder="$lang('common.dialog__name')">
                            <template slot="prepend">
                                <i class="iconfont iconzhanghao"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <el-input v-model="formData.phone" :placeholder="$lang('common.dialog__phone')">
                            <template slot="prepend">
                                <i class="iconfont iconshouji-copy"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="email">
                        <el-input v-model="formData.email" :placeholder="$lang('common.dialog__email')">
                            <template slot="prepend">
                                <i class="iconfont iconyouxiang"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="message">
                        <el-input type="textarea" v-model="formData.message" :placeholder="$lang('common.dialog__message')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="rule-button" @click="messageSub('ruleForm')"> {{ $lang("common.submit") }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { aboutUsMessage } from "@/api/cms/notice"; 
    export default {
        data() {
            var isPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("Mobile phone number cannot be empty"))
                } else {
                    // Support both formats: numbers with + sign and plain numbers
                    const internationalRegex = /^(\+\d{1,}|\d{5,})$/;
                    if (internationalRegex.test(value)) {
                        callback()
                    } else {
                        callback(new Error("Please enter a valid phone number with optional country code (e.g. +8615591806627)"))
                    }
                }
            }
            return {
                viewStyle: {
                    top: window.innerHeight - 100 + "px",
                    right: "0px"
                },
                systemInfo: {
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight
                },
                isShow: true,
                isTel: false,
                isEmail: false,
                isPhone: false,
                isQrcode: false,
                isAtTop: true,
                dialogVisible: false,
                formData: {
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                }, // 表单数据
                messageRules: {
                    name: [
                        {
                            required: true,
                            name: "Please type in your name",
                            trigger: "blur"
                        }
                    ],
                    phone: [
                        {
                            required: true,
                            validator: isPhone,
                            trigger: "blur"
                        }
                    ],
                    email: [
                        {
                            required: true,
                            email: "Please type in your email",
                            trigger: "blur"
                        }
                    ],
                    message: [
                        {
                            required: true,
                            message: "Please enter a message",
                            trigger: "blur"
                        }
                    ]
                }
            }
        },
        props:{
            isMobile:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            rightDivStyle() {
                let obj = { width: "40px", height: "40px" }
                if (!this.isShow){
                    obj = { width: "30px", height: "30px" }
                }
                return obj
            },
            rightImageStyle() {
                let obj = { width: "30px", height: "30px" }
                if (!this.isShow){
                    obj = { width: "20px", height: "20px" }
                }
                return obj
                // return this.isShow ? { width: "30px", height: "30px" } : { width: "20px", height: "20px" }
            },
            isTop() {
                console.log("window.screenY", window.screenY)

                return window.screenY >= 100 ? true : false
            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll)
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll)
        },
        methods: {
            handleScroll() {
                // 更新 isAtTop 状态
                this.isAtTop = window.scrollY <= 50
                console.log('this.isAtTop',this.isAtTop);
                
            },
            rightHandle() {
                this.isShow = !this.isShow
            },
            upHandle() {
                this.$emit("upHandle")
            },
            chatHandle() {
                this.dialogVisible = true
            },
            downHandle() {
                this.$emit("downHandle")
            },
            messageSub(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var data = {
                            name: this.formData.name,
                            phone: this.formData.phone,
                            email: this.formData.email,
                            message: this.formData.message
                        }

                        // Make sure the aboutUsMessage function is properly called
                        const submitMessage = aboutUsMessage(data);
                        
                        submitMessage.then((res) => {
                                if (res.code >= 0) {
                                    this.$message({
                                        message: "Message successfully！",
                                        type: "success"
                                    })
                                    setTimeout(() => {
                                        location.reload()
                                    }, 2000)
                                } else {
                                    this.$message({
                                        message: res.message,
                                        type: "warning"
                                    })
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err.message || "Submission failed")
                            })
                    } else {
                        return false
                    }
                })
            }
        }
    }

</script>

<style scoped lang="scss">
    .global-wrap {
        position: fixed;
        z-index: 9999;
        &__content {
            position: relative;
            &__right {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                background: #409eff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            &__box {
                position: absolute;
                bottom: 60px;
                right: 0;
                background: #409eff;
                width: 100%;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                // text-align: center;
                box-sizing: border-box;

                &__item {
                    cursor: pointer;
                    padding: 10px 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        background: rgba(0, 0, 0, 0.1);
                    }
                    &__text {
                        position: absolute;
                        top: 0;
                        right: 40px;
                        width: 160px;
                        padding: 10px;
                        box-sizing: border-box;
                        height: 40px;
                        text-align: center;
                        color: #409eff;
                        background-color: rgba(255, 255, 255, 1);
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
    .tel-qjp,
    .email-qjp,
    .phone-qjp,
    .qrcode-qjp {
        position: relative;
    }

    .formItem ::v-deep .el-form-item {
        width: 200px;
        padding: 0 10px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        &:last-child {
            border-bottom: none;
        }
    }
    .elRofm {
        width: 100%;
    }
    ::v-deep .el-dialog {
        width: 600px !important;
    }
    .active-dialog  ::v-deep .el-dialog {
        width: 350px !important;
    }
    .slide-x-enter-active,
    .slide-x-leave-active {
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateX(0);
    }

    .slide-x-enter {
        transform: translateX(100%);
    }

    .slide-x-leave-to {
        transform: translateX(100%);
    }
</style>
