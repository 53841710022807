export const lang = {
	//公共
	common: {
		//语言包名称
		name: 'ru',
		contact_us: 'Свяжитесь с нами',
		company_name:'Нанкин Соо насосный клапан лтд.',
		email:'Электронная почта',
		tel:"Телефон",
		submit:'Представлено',
		home:'Главная страница',
		header_searchInputPlaceholder: 'Пожалуйста, введите ключевые слова товаров.',
		header_pleaseLogin: 'Здравствуйте, пожалуйста, войдите',
		header_freeRegistry: 'Бесплатная регистрация',
		header_applyEnter: 'Заявки на проживание',
		header_myOrder: 'Мой заказ.',
		header_memberCenter: 'Членский центр',
		header_helperCenter: 'Центр помощи',
		header_onlineService: 'Онлайн обслуживание клиентов',
		header_phoneVersion: 'Мобильная версия',
		header_cart: 'Моя тележка.',
		footer_customerService: 'Обслуживание клиентов',
		footer_onlineService: 'Онлайн обслуживание клиентов',
		footer_feedback: 'Обратная связь',
		footer_followUs: 'Заботьтесь о нас',
		footer_wechatPublic: 'Публичный номер',
		footer_webo: 'Микроблог',
		footer_miniProgram: 'ТикТок',
		footer_contract: 'Создание онлайновых контрактов',
		footer_logistics: 'Будьте уверены в логистике',
		footer_quality: 'Гарантия качества в советско - европейском торговом центре',
		footer_aboutUs: 'О нас',
		footer_helpCenter: 'Центр помощи',
		footer_afterSell: 'Послепродажное обслуживание',
		footer_delivery: 'Доставка и приемка',
		footer_cooperation: 'Торговое сотрудничество',
		footer_purchase: 'Закупки предприятий',
		footer_openPlatform: 'Открытая платформа',
		footer_searchRecommend: 'Поиск рекомендаций',
		footer_friendshipLink: 'Дружеские связи',
		footer_contactUs:'Свяжитесь с нами 24 часа',
		footer_address:'Адрес: Комната 1603-1605, Здание G1, Площадь Гринланд, ул. Цзинъюань, дом 8, Нанкин, Китай',
		hotSales: 'Круглосуточная распродажа.',
		productsList:"Список товаров",
		expand:'Развернуть',
		classification:'Классификация товаров не проводилась',
		empty:"Не могу найти продукт, который вы ищете. Попробуйте в разных условиях.",
		next_page:"Следующая страница",
		prev_page:"Предыдущая страница",
		footer_registration:'Регистрационный номер',
		dialog__name:'Введите ваше имя',
		dialog__phone:'Пожалуйста, введите номер вашего телефона.',
		dialog__email:'Введите свой почтовый ящик',
		dialog__message:'Введите сообщение',
		dialog__text:'Если вы заинтересованы в наших продуктах и хотите узнать больше деталей, пожалуйста, оставьте здесь сообщение. Мы ответим вам как можно скорее',
        popular_products:'Популярные товары'
	}
}
