export const lang = {
    //公共
    common: {
        //语言包名称
        name: "中文",
        contact_us: "联系我们",
        company_name: "南京索欧泵阀有限公司",
        email: "邮箱",
        tel: "电话",
        submit: "提交",
        home: "首页",
        header_searchInputPlaceholder: "请输入商品关键词",
        header_pleaseLogin: "您好，请登录",
        header_freeRegistry: "免费注册",
        header_applyEnter: "申请入驻",
        header_myOrder: "我的订单",
        header_memberCenter: "会员中心",
        header_helperCenter: "帮助中心",
        header_onlineService: "在线客服",
        header_phoneVersion: "手机版",
        header_cart: "我的购物车",
        footer_customerService: "客户服务",
        footer_onlineService: "在线客服",
        footer_feedback: "问题反馈",
        footer_followUs: "关注我们",
        footer_wechatPublic: "公众号",
        footer_webo: "微博",
        footer_miniProgram: "抖音",
        footer_contract: "在线合同生成",
        footer_logistics: "放心物流",
        footer_quality: "苏欧商城品质保证",
        footer_aboutUs: "关于我们",
        footer_helpCenter: "帮助中心",
        footer_afterSell: "售后服务",
        footer_delivery: "配送与验收",
        footer_cooperation: "商务合作",
        footer_purchase: "企业采购",
        footer_openPlatform: "开放平台",
        footer_searchRecommend: "搜索推荐",
        footer_friendshipLink: "友情链接",
        footer_contactUs: "24小时联系我们",
        footer_address: "地址：中国南京市靖远路8号绿地广场G1栋1603-1605室",
        hotSales: "24小时热销榜",
        productsList: "商品列表",
        expand: "展开",
        classification: "尚未进行商品分类",
        empty: "找不到您要查找的产品。在不同条件下尝试",
        next_page: "下一页",
        prev_page: "上一页",
        footer_registration: "注册号",
        dialog__name: "请输入您的名字",
        dialog__phone: "请输入您的手机号",
        dialog__email: "请输入您的邮箱",
        dialog__message: "请输入一条消息",
		dialog__text:'如果您对我们的产品感兴趣并想了解更多细节，请在此处留言，我们会尽快回复您。',
        popular_products:'热门产品'
    }
}
