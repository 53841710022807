<template>
    <div class="draggableBack" ref="draggableBackRef" :style="viewStyle" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
        <img style="width: 100%; height: 100%; background-color: #fff" src="@/assets/back.png" alt="" />
    </div>
</template>
<script>
    export default {
        name: "draggableBack",
        data() {
            return {
                axle: {
                    xAxle: 0,
                    yAxle: 0
                },
                clickLoading: false,
                viewStyle: {
                    top: window.innerHeight - 100 + "px",
                    left: "0px"
                },
                systemInfo: {
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight
                },
                width: 40,
                height: 40,
                touchStartTime: 0,
                isDragging: false,
                moveThreshold: 5,
                clickTimeThreshold: 300
            }
        },
        mounted() {
            // this.initPosition()
        },
        methods: {
            handleTouchStart(event) {
                console.log("触摸", event.touches[0])
                this.clickLoading = false
                this.axle = {
                    xAxle: event.touches[0].pageX,
                    yAxle: event.touches[0].pageY
                }
                this.touchStartTime = Date.now() // 记录触摸开始时间
                this.isDragging = false
            },
            handleTouchMove(event) {
                const touch = event.touches[0]
                console.log("滑动事件", touch)
                const dx = Math.abs(touch.pageX - this.axle.xAxle)
                const dy = Math.abs(touch.pageY - this.axle.yAxle)
                console.log(dx > this.moveThreshold || dy > this.moveThreshold)
                console.log(dx)
                console.log(dy)

                if (dx > this.moveThreshold || dy > this.moveThreshold) {
                    this.isDragging = true
                }
                if (this.isDragging) {
                    // 计算元素居中的位置
                    const newTop = touch.clientY - this.height / 2
                    const newLeft = touch.clientX - this.width / 2

                    // 限制元素在可视范围内
                    const maxTop = this.systemInfo.screenHeight - this.height
                    const maxLeft = this.systemInfo.screenWidth - this.width

                    const finalTop = Math.max(0, Math.min(newTop, maxTop))
                    const finalLeft = Math.max(0, Math.min(newLeft, maxLeft))

                    console.log("finalTop", finalTop)
                    console.log("finalLeft", finalLeft)

                    this.viewStyle = {
                        top: `${finalTop}px`,
                        left: `${finalLeft}px`
                    }
                    console.log("!", this.viewStyle)
                }
            },
            handleTouchEnd() {
                const touchDuration = Date.now() - this.touchStartTime
                // 如果触摸时间小于阈值且未发生拖动，则判定为点击事件
                if (touchDuration < this.clickTimeThreshold && !this.isDragging) {
                    this.handleClick()
                }
            },
            handleClick() {
                // console.log("元素被点击了")
                this.$router.back()
                const _this = this
                setTimeout(() => {
                const newUrl = _this.updateLangInQuery()
                console.log('newUrl',newUrl);
                    window.location.href = newUrl
                }, 100)
            },
            updateLangInQuery() {
                const url = new URL(window.location.href)
                const params = new URLSearchParams(url.search)
                url.search = params.toString()
                return url.toString()
            }
        }
    }
</script>

<style scoped lang="scss">
    .draggableBack {
        width: 40px;
        height: 40px;
        overflow: hidden;
        position: fixed;
        background-color: transparent;
        top: 0px;
        z-index: 9999;
        border-radius: 10px;
        // transition: all 0.3s ease; /* 吸附时添加过渡效果 */
        // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* 添加阴影提升视觉效果 */
    }
</style>
