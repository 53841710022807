export const lang = {
	//公共
	common: {
		//语言包名称
		name: 'الصينية .',
		contact_us: 'اتصل بنا',
		company_name:'شركة نانجينغ سوو لمضخات وصمامات المحدودة',
		email:'صندوق البريد',
		tel:"تلفون .",
		submit:'قدم',
		home:'الصفحة الرئيسية',
		header_searchInputPlaceholder: 'يرجى إدخال الكلمات الرئيسية للسلع',
		header_pleaseLogin: 'مرحبا ، يرجى تسجيل الدخول',
		header_freeRegistry: 'تسجيل مجاني',
		header_applyEnter: '',
		header_myOrder: 'طلب الإقامة',
		header_memberCenter: 'مركز الأعضاء',
		header_helperCenter: 'مركز المساعدة',
		header_onlineService: 'خدمة العملاء عبر الإنترنت',
		header_phoneVersion: 'نسخة الهاتف',
		header_cart: 'عربة التسوق الخاصة بي',
		footer_customerService: 'خدمة العملاء',
		footer_onlineService: 'خدمة العملاء عبر الإنترنت',
		footer_feedback: 'ردود الفعل',
		footer_followUs: 'تابعنا',
		footer_wechatPublic: 'حساب WeChat العام',
		footer_webo: 'Weibo',
		footer_miniProgram:'تيك توك',
		footer_contract: 'إنشاء عقد عبر الإنترنت',
		footer_logistics: 'خدمة لوجستية موثوقة',
		footer_quality: 'ضمان جودة متجر Suo Ou',
		footer_aboutUs: 'معلومات عنا',
		footer_helpCenter: 'مركز المساعدة',
		footer_afterSell: 'خدمة ما بعد البيع',
		footer_delivery: 'التسليم والاستلام',
		footer_cooperation: 'التعاون التجاري',
		footer_purchase: 'المشتريات المؤسسية',
		footer_openPlatform: 'منصة مفتوحة',
		footer_searchRecommend: 'بحث وتوصية',
		footer_friendshipLink: 'روابط الصداقة',
		footer_contactUs:'اتصل بنا على مدار 24 ساعة',
		footer_address:'العنوان: غرفة 1603-1605، مبنى G1، ساحة جرينلاند، رقم 8 طريق جينغيوان، مدينة نانجينغ، الصين',
		hotSales: 'قائمة أفضل المبيعات خلال 24 ساعة',
		productsList:"قائمة المنتجات",
		expand:'توسيع',
		classification:'لم يتم تصنيف المنتجات بعد',
		empty:"لم يتم العثور على المنتج الذي تبحث عنه. حاول بشروط مختلفة",
		next_page:"الصفحة التالية",
		prev_page:"الصفحة السابقة",
		footer_registration:'رقم التسجيل',
		dialog__name:'من فضلك أدخل اسمك',
		dialog__phone:'من فضلك أدخل رقم هاتفك',
		dialog__email:'الرجاء إدخال البريد الإلكتروني الخاص بك',
		dialog__message:'الرجاء إدخال رسالة',
		dialog__text:'إذا كنت مهتمًا بمنتجاتنا وتريد معرفة المزيد من التفاصيل ، فيرجى остав رسالة هنا وسنرد عليك في أقرب وقت ممكن',
        popular_products:'منتجات مشهورة '
	}
}