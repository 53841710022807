export const lang = {

	//公共
	common: {
		//语言包名称
		name: 'En',
		contact_us: 'Contact Us',
		company_name:'Nanjing Suou Pump Valve Co., Ltd.',
		email:'Email',
		tel:"TEL",
		submit:'submit',
		home:'Home',
		header_searchInputPlaceholder: 'Please input product keyword',
		header_pleaseLogin: 'Please login',
		header_freeRegistry: 'Registry',
		header_applyEnter: 'SettleIn',
		header_myOrder: 'Order',
		header_memberCenter: 'Vip',
		header_helperCenter: 'Help',
		header_onlineService: 'Service',
		header_phoneVersion: 'Mobile',
		header_cart: 'Cart',
		footer_customerService: 'Customer Service',
		footer_onlineService: 'Service',
		footer_feedback: 'Feedback',
		footer_followUs: 'Follow Us',
		footer_wechatPublic: 'Offical',
		footer_webo: 'WeiBo',
		footer_miniProgram: 'TikTok',
		footer_serviceHotline: 'Contact Us',
		footer_contract: 'Online Contract Generate',
		footer_logistics: 'Assured Logistics',
		footer_quality: 'Quality assurance',
		footer_aboutUs: 'About Us',
		footer_helpCenter: 'Help Center',
		footer_afterSell: 'After-Sell Service',
		footer_delivery: 'Delivery and Acceptance',
		footer_cooperation: 'Business Cooperation',
		footer_purchase: 'Purchase',
		footer_openPlatform: 'Open Platform',
		footer_searchRecommend: 'Search Recommend',
		footer_friendshipLink: 'Friendship Link',
		footer_contactUs:'Contact Us 24 hour',
		footer_address:'Address: Room 1603-1605, Building G1, Greenland Plaza, No. 8 Jingyuan Road, Nanjing, China',
		hotSales: 'Hot Sales',
		productsList:"products list",
		expand:'Expand',
		classification:'No product classification yet',
		empty:"The product you are looking for was not found. Try it under different conditions",
		next_page:"Next Page",
		prev_page:"Previous Page",
		footer_registration:'Registration Number',
		dialog__name:'Please enter your name',
		dialog__phone:'Please enter your phone number',
		dialog__email:'Please enter your email address',
		dialog__message:'Please enter a message',
		dialog__text:'if you are interested in our products and want to know more details, please leave a message here , we will reply you as soon as we can',
        popular_products:'Popular products'
	}
}
