import Vue from "vue"
import VueRouter from "vue-router"
import Lang from "../utils/lang.js"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import indexRoutes from "./modules/index"
import memberRoutes from "./modules/member"
import shopRoutes from "./modules/shop"
import authRoutes from "./modules/auth"
import { getToken } from "../utils/auth"
import store from "../store"
import i18n from "@/lang/index.js"

// 屏蔽跳转到本身时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.location.open(location, "_self")

    return originalPush.call(this, location).catch((err) => err)
}

const originalResolve = VueRouter.prototype.resolve
VueRouter.prototype.pushToTab = function pushToTab(location) {
    if (!location) return
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.open(location, "_blank")

    const { href } = originalResolve.call(this, location)
    window.open(href, "_blank")
}

Vue.use(VueRouter)

/**
 * meta参数解析
 * module: 所属模块，目前用于寻找语言包
 * module: 菜单所属模块
 * menu: 所属菜单，用于判断三级菜单是否显示高亮，如菜单列表、添加菜单、编辑菜单都是'menu'，用户列表、添加用户、编辑用户都是'user'，如此类推
 */
const mainRouters = [
    indexRoutes,
    authRoutes,
    memberRoutes,
    shopRoutes,
    {
        path: "/closed",
        name: "closed",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/closed.vue")
    },
    {
        path: "*",
        name: "error",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/error.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: "/web/",
    routes: mainRouters
})

// 路由守卫，控制访问权限
router.beforeEach((to, from, next) => {
    if (store.getters.siteInfo && !store.getters.siteInfo.web_status && to.path != "/closed") {
        return next("/closed")
    }

    if (to.meta.auth) {
        const token = getToken()
        if (!token) {
            return next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
        }
    }

    window.document.body.style.backgroundColor = to.meta.backgroundColor || ""

    NProgress.start()
    next()
})

router.afterEach((to, from) => {
    // 添加路由栈

    if (to.query.lang) {
        console.log("当前语言：", to.query.lang)
        if (to.query.lang == "zh") {
            Lang.change("zh-cn")
        } else if (to.query.lang == "en") {
            Lang.change("en-us")
        } else {
            Lang.change(to.query.lang)
        }
    }
    const lang = localStorage.getItem("language") || "en"

    const langUageMap = {
        "zh": {
            title: '南京苏欧泵阀有限公司',
            description: "泵、阀门、密封件",
            keywords: "泵，水泵，凯士比泵，赛莱默泵，威乐泵，格兰富泵，污水泵，中国泵，排污泵"
        },
        "ar": {
            title: 'شركة نانجينغ سوو بومب و فالو المحدودة',
            description: "مضخة، صمام، ختم",
            keywords: "مضخة، مضخة مياه، مضخة كسب، مضخة زيلم، مضخة فيلو، مضخة غروندفوس، مضخة مياه الصرف الصحي، مضخة صينية، مضخة مياه الصرف"
        },
        "de": {
            title: 'Nanjing Suoou Pumpen und Ventile Co., Ltd',
            description: "Pumpe, Ventil, Dichtung",
            keywords: "Pumpe, Wasserpumpe, KSB-Pumpe, Xylem-Pumpe, Wilo-Pumpe, Grundfos-Pumpe, Abwasserpumpe, Chinesische Pumpe, Abwassersaugpumpe"
        },
        "en": {
            title: 'Nanjing Suou Pump Valve Co., Ltd.',
            description: "pump、Valve、Seal",
            keywords: "pump，water pump，KSB pump，xylem pump，wilo pump，grundfos pump，waster water pump，Chinese pump，sewage pump"
        },
        "fr": {
            title: 'Société Nanjing Suoou Pompes et Vannes Co., Ltd',
            description: "Pompe, Vanne, Joint d'étanchéité",
            keywords: "Pompe, Pompe à eau, Pompe KSB, Pompe Xylem, Pompe Wilo, Pompe Grundfos, Pompe d'eaux usées, Pompe chinoise, Pompe à eaux usées"
        },
        "id": {
            title: 'Nanjing Suoou Pompa dan Valve Co., Ltd.',
            description: "Pompa, Katup, Segel",
            keywords: "Pompa, Pompa air, Pompa KSB, Pompa Xylem, Pompa Wilo, Pompa Grundfos, Pompa air limbah, Pompa Cina, Pompa air kotor"
        },
        "ru": {
            title: 'Нанкин Соо насосный клапан лтд.',
            description: "Насос, Клапан, Герметик",
            keywords: "Насос, Водяной насос, Насос КСБ, Насос Ксилем, Насос Вило, Насос Грунфос, Сточный насос, Китайский насос, Сливной насос"
        },
        "vi":{
            title:"Công ty TNHH Bơm Van Nam Kinh Suo Ou",
            description: "Bơm, Van, Khóa kín",
            keywords: "Bơm, Bơm nước, Bơm KSB, Bơm Xylem, Bơm Wilo, Bơm Grundfos, Bơm nước thải, Bơm Trung Quốc, Bơm nước thải"
        }
    };
    const title = langUageMap[lang].title
    const description = langUageMap[lang].description
    const keywords = langUageMap[lang].keywords
    setMetaTag('description',description)
    setMetaTag('keywords',keywords)
    window.document.title = `${title}`
    // console.log("当前语言->：", localStorage.getItem("language"))
    // console.log("当前语言->：", router.app.$t('common.name'));
    // const title = Lang.getLangField("title", to)
    // console.log('title',title);

    // const metaTitle = store.getters.siteInfo.title || "HongYunShop"
    // window.document.title = `${title} - ${metaTitle}`
    // window.document.title = `${title} - ${metaTitle}`
    setTimeout(() => {
        if (document.getElementsByClassName("el-main").length) {
            if (to.meta.mainCss) {
                for (let field in to.meta.mainCss) {
                    document.getElementsByClassName("el-main")[0].style[field] = to.meta.mainCss[field]
                }
            } else {
                document.getElementsByClassName("el-main")[0].style = ""
            }
        }
    }, 100)
    NProgress.done()
})

const setMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`)
    if (!metaTag) {
        metaTag = document.createElement("meta")
        metaTag.setAttribute("name", name)
        document.head.appendChild(metaTag)
    }
    metaTag.setAttribute("content", content)
}

export default router
