<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
        <draggable-back v-if="isMobile"></draggable-back>
        <!-- <global-window v-if="!isMobile && nestTikeFlag"></global-window> -->
        <!-- <global-window-right ></global-window-right> -->
        <global-window-right v-if="nestTikeFlag" @upHandle="upHandle" @downHandle="downHandle" :isMobile="isMobile"></global-window-right>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters } from "vuex"
    import http from "@/utils/http"
    import draggableBack from "@/components/draggableBack.vue"
    import globalWindow from "./components/globalWindow.vue"
    import globalWindowRight from "./components/globalWindowRight.vue"
    export default {
        name: "APP",
        data() {
            return {
                nestTikeFlag: false
            }
        },
        components: {
            draggableBack,
            globalWindow,
            globalWindowRight
        },
        computed: {
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        mounted() {
            this.initDevice()
            // this.SET_LANG('vi');
            // this.$i18n.locale = this.$store.state.app.language
            /*            let lang = localStorage.getItem('lang')
                        lang = 'en_US'
                        console.log("lang: ", lang)
                        if (lang == 'en_US') {
                            this.$i18n.locale = "en"
                        } else {
                            this.$i18n.locale = "zh"
                            this.locale = null
                        }*/
            if (localStorage.getItem("language")) {
                this.$i18n.locale = localStorage.getItem("language")
            } else {
                this.$i18n.locale = "en"
                localStorage.setItem("language", "en")
            }
            this.$nextTick(() => {
                this.nestTikeFlag = true
            })
        },
        methods: {
            ...mapActions("device", ["initDevice"]),
            upHandle() {
                console.log('?');
                
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            },
            downHandle() {
                console.log('?');
                window.scrollTo({
                    top: document.documentElement.scrollHeight - window.innerHeight,
                    behavior: "smooth"
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import "assets/styles/main.scss";
</style>
