<template>
    <div class="global-wrap" :style="viewStyle">
        <div class="globalWindow">
            <div class="icon-container" @mouseenter="isShow = true" @mouseleave="isShow = false" @click="dialogVisible = true">
                <img style="width: 25px; height: 25px" :src="require('@/assets/chat.png')" alt="" />
            </div>
            <div class="formItem" v-if="isShow" style="position: absolute; bottom: 60px; right: 0; background-color: rgba(0, 0, 0, 0.5); border-radius: 10px">
                <el-form label-width="auto" style="max-width: 600px">
                    <el-form-item>
                        <template #label>
                            <img class="label-img" :src="require('@/assets/tel.png')" alt="" />
                        </template>
                        <span class="label-text">+86 02558808446</span>
                    </el-form-item>
                    <el-form-item>
                        <template #label>
                            <img class="label-img" :src="require('@/assets/email.png')" alt="" />
                        </template>
                        <span class="label-text">info@soppv.com</span>
                    </el-form-item>
                    <el-form-item>
                        <template #label>
                            <img class="label-img" :src="require('@/assets/phone.png')" alt="" />
                        </template>
                        <span class="label-text">+86 13914479750</span>
                    </el-form-item>
                    <el-form-item>
                        <template #label>
                            <img class="label-img" :src="require('@/assets/qrcode.png')" alt="" />
                        </template>
                        <div class="label-text">whatsApp</div>
                        <el-image style="width: 100px; height: 100px" :src="require('@/assets/whatsApp.png')" fit="contain"> </el-image>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :title="$lang('common.contact_us')"  >
            <div style="margin-bottom: 20px;">{{ $lang('common.dialog__text') }}</div>
            <div class="elRofm" style="" >
                <el-form :model="formData" :rules="messageRules" ref="ruleForm">
                    <el-form-item prop="name">
                        <el-input v-model="formData.name" :placeholder="$lang('common.dialog__name')">
                            <template slot="prepend">
                                <i class="iconfont iconzhanghao"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <el-input v-model="formData.phone" :placeholder="$lang('common.dialog__phone')">
                            <template slot="prepend">
                                <i class="iconfont iconshouji-copy"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="email">
                        <el-input v-model="formData.email" :placeholder="$lang('common.dialog__email')">
                            <template slot="prepend">
                                <i class="iconfont iconyouxiang"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="message">
                        <el-input type="textarea" v-model="formData.message" :placeholder="$lang('common.dialog__message')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="rule-button" @click="messageSub('ruleForm')"> {{ $lang("common.submit") }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { aboutUsMessage } from "@/api/cms/notice"
    export default {
        data() {
            const isPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("Mobile phone number cannot be empty"))
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                    if (reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error("please enter a valid phone number"))
                    }
                }
            }
            return {
                viewStyle: {
                    top: window.innerHeight - 100 + "px",
                    right: "20px"
                },
                systemInfo: {
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight
                },
                isShow: false,
                dialogVisible: false,
                formData: {
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                }, // 表单数据
                messageRules: {
                    name: [
                        {
                            required: true,
                            name: "Please type in your name",
                            trigger: "blur"
                        }
                    ],
                    phone: [
                        {
                            required: true,
                            validator: isPhone,
                            trigger: "blur"
                        }
                    ],
                    email: [
                        {
                            required: true,
                            email: "Please type in your email",
                            trigger: "blur"
                        }
                    ],
                    message: [
                        {
                            required: true,
                            message: "Please enter a message",
                            trigger: "blur"
                        }
                    ]
                }
            }
        },
        methods: {
            messageSub(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var data = {
                            name: this.formData.name,
                            phone: this.formData.phone,
                            email: this.formData.email,
                            message: this.formData.message
                        }

                        aboutUsMessage(data)
                            .then((res) => {
                                if (res.code >= 0) {
                                    this.$message({
                                        message: "Message successfully！",
                                        type: "success"
                                    })
                                    setTimeout(() => {
                                        location.reload()
                                    }, 2000)
                                } else {
                                    this.$message({
                                        message: res.message,
                                        type: "warning"
                                    })
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err.message)
                            })
                    } else {
                        return false
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .global-wrap {
        position: fixed;
        z-index: 9999;
    }
    .globalWindow {
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }
    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }
    .label-img {
        width: 20px;
        height: 20px;
    }

    .label-text {
        color: #fff;
    }
    .formItem ::v-deep .el-form-item {
        width: 200px;
        padding: 0 10px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        &:last-child {
            border-bottom: none;
        }
    }
    .elRofm {
        width: 100%;
    }
    ::v-deep .el-dialog{
        width: 600px !important;
    }
</style>
