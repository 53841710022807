export const lang = {
	// Commun
	common: {
		// Nom du pack de langue
		name: 'Chinois',
		contact_us: 'Contactez-nous',
		company_name: 'Société Nanjing Suoou Pompes et Vannes Co., Ltd',
		email: 'E-mail',
		tel: "Téléphone",
		submit: 'Soumettre',
		home: 'Accueil',
		header_searchInputPlaceholder: 'Veuillez entrer des mots-clés pour les produits',
		header_pleaseLogin: 'Bonjour, veuillez vous connecter',
		header_freeRegistry: 'Inscription gratuite',
		header_applyEnter: 'Demande d\'adhésion',
		header_myOrder: 'Mes commandes',
		header_memberCenter: 'Centre des membres',
		header_helperCenter: 'Centre d\'aide',
		header_onlineService: 'Service client en ligne',
		header_phoneVersion: 'Version mobile',
		header_cart: 'Mon panier',
		footer_customerService: 'Service client',
		footer_onlineService: 'Service client en ligne',
		footer_feedback: 'Retour d\'information',
		footer_followUs: 'Suivez-nous',
		footer_wechatPublic: 'Public WeChat',
		footer_webo: 'Weibo',
		footer_miniProgram: 'TikTok',
		footer_contract: 'Génération de contrat en ligne',
		footer_logistics: 'Logistique fiable',
		footer_quality: 'Garantie de qualité de Suoou Mall',
		footer_aboutUs: 'À propos de nous',
		footer_helpCenter: 'Centre d\'aide',
		footer_afterSell: 'Service après-vente',
		footer_delivery: 'Livraison et réception',
		footer_cooperation: 'Coopération commerciale',
		footer_purchase: 'Achats d\'entreprise',
		footer_openPlatform: 'Plateforme ouverte',
		footer_searchRecommend: 'Recommandation de recherche',
		footer_friendshipLink: 'Liens d\'amitié',
		footer_contactUs: 'Contactez-nous 24h/24',
		footer_address: 'Adresse : Chambre 1603-1605, Bâtiment G1, Place Greenland, No 8 Rue Jingyuan, Nanjing, Chine',
		hotSales: 'Top des ventes en 24 heures',
		productsList: "Liste des produits",
		expand: 'Développer',
		classification: 'Aucune classification de produits effectuée',
		empty: "Le produit que vous recherchez est introuvable. Essayez sous différentes conditions",
		next_page: "Page suivante",
		prev_page: "Page précédente",
		footer_registration: 'Numéro d\'enregistrement',
		dialog__name:'Veuillez entrer votre nom',
		dialog__phone:'Veuillez entrer votre numéro de téléphone',
		dialog__email:'Veuillez entrer votre e-mail',
		dialog__message:'Veuillez entrer un message',
		dialog__text:'Si vous êtes intéressé par nos produits et que vous souhaitez connaître plus de détails, veuillez laisser un message ici. Nous vous répondrons dès que possible',
        popular_products:'Produits populaires'
	}
}