<template>
    <div class="footer">
        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">
            <el-tab-pane label="友情链接" name="first">
                <div>
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
                    </div>

                    <div class="clear"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">友情链接</div>
			<div>
				<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
					<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
				</div>

				<div class="clear"></div>
			</div>
		</div> -->
        <div class="footer-top" v-if="!isMobile">
            <div class="footer-top-inner">
                <!-- <div class="line-box">
                    <p>{{ $lang('common.footer_customerService') }}</p>
                    <div class="function-box">
                        <div class="function-item">
                            <div class="function-item-icon"></div>
                            <div class="function-item-title">{{ $lang('common.footer_onlineService') }}</div>
                        </div>
                        <div class="function-item">
                            <div class="function-item-icon"></div>
                            <div class="function-item-title">{{ $lang('common.footer_feedback') }}</div>
                        </div>
                    </div>
                </div> -->
                <div class="line-box" style="color: #fff; margin-top: 80px">
                    <div style="text-align: left">{{ $lang("common.company_name") }}</div>
                    <div style="text-align: left">{{ $lang("common.footer_address") }}</div>
                    <div style="text-align: left">{{ $lang("common.footer_registration") }}：苏ICP备2024100152号</div>
                </div>
                <div class="line-box mini-programe">
                    <p>{{ $lang("common.footer_miniProgram") }}</p>
                    <el-image v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError" fit="contain"> </el-image>
                    <el-image v-else style="border-radius: 10px; width: 150px; height: 150px" :src="require('@/assets/images/tiktok.png')"></el-image>
                </div>
                <div class="line-box">
                    <p>{{ $lang("common.contact_us") }}</p>
                    <div v-if="siteInfo.web_phone" class="phone-number">+86 02558808446</div>
                    <div class="tips-line">{{ $lang("common.footer_contactUs") }}</div>
                    <div class="tips-line">{{ $lang("common.contact_us") }}</div>
                </div>
            </div>
        </div>
        <div v-else class="footer-mobile" style="text-align: center">
            <img :src="require('@/assets/index_img/首页_65.jpg')" style="cursor: pointer" @click="goAboutUsPage" />
        </div>

        <!-- <div class="footer-bot">
            <div class="footer-bot-wrapper">
                <div class="item">
                    <div><img :src="require('@/assets/images/icon_1.png')" alt=""></div>
                    <div>{{ $lang('common.footer_contract') }}</div>
                </div>
                <div class="item">
                    <div><img :src="require('@/assets/images/icon_2.png')" alt=""></div>
                    <div>{{ $lang('common.footer_logistics') }}</div>
                </div>
                <div class="item">
                    <div><img :src="require('@/assets/images/icon_3.png')" alt=""></div>
                    <div>{{ $lang('common.footer_quality') }}</div>
                </div>
            </div>
        </div> -->
        <!-- <div class="footer-fot">
            <div class="footer-fot-wrapper">
                <div class="down-line">
                    <copy-right></copy-right>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import { copyRight, shopServiceLists, friendlyLink } from "@/api/website"
    import CopyRight from "./CopyRight"
    import { mapGetters } from "vuex"
    import { helpList } from "@/api/cms/help"

    export default {
        props: {},
        data() {
            return {
                shopServiceList: [],
                linkList: [],
                helpList: [],
                ishide: false,
                activeName: "first"
            }
        },
        computed: {
            ...mapGetters(["siteInfo"]),
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        created() {
            this.getShopServiceLists()
            this.link()
            this.getHelpList()
        },
        mounted() {},
        watch: {},
        methods: {
          
            getShopServiceLists() {
                shopServiceLists({}).then((res) => {
                    if (res.code == 0 && res.data) {
                        this.shopServiceList = res.data
                    }
                })
            },
            link() {
                friendlyLink({})
                    .then((res) => {
                        if (res.code == 0 && res.data) {
                            this.linkList = res.data.list
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                    })
            },
            linkUrl(url, target) {
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) this.$router.pushToTab({ path: url })
                    else this.$router.push({ path: url })
                } else {
                    if (target) window.open(url)
                    else window.location.href = url
                }
            },
            /**
             * 获取帮助列表
             */
            getHelpList() {
                helpList()
                    .then((res) => {
                        if (res.code == 0 && res.data) {
                            var arr = []
                            arr = res.data.slice(0, 4)

                            for (let i = 0; i < arr.length; i++) {
                                arr[i].child_list = arr[i].child_list.slice(0, 4)
                            }

                            this.helpList = arr
                        }
                    })
                    .catch((err) => {})
            },
            /**
             * 图片加载失败
             */
            imageError() {
                this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
            },
            /**
             * 跳转到帮助列表
             */
            clickToHelp(id) {
                this.$router.push("/cms/help/listother-" + id)
            },
            /**
             * 跳转到帮助详情
             */
            clickToHelpDetail(id) {
                this.$router.push("/cms/help-" + id)
            },
            goAboutUsPage() {
                const url = `/cms/contactUs`
                window.location.href = url
            }
        },
        components: { CopyRight }
    }
</script>

<style scoped>
    .right /deep/ .el-image__inner {
        vertical-align: baseline;
        max-width: 100%;
        max-height: 100%;
    }
</style>
<style scoped lang="scss">
    .footer {
        .footer-top {
            // background-color: #fff;
            background-color: #333333;

            .footer-top-inner {
                margin: 0;
                padding: 0;
                width: $width;
                padding: 0 0 25px 0;
                margin: 0 auto;
                display: flex;
                justify-content: center;

                .line-box:not(:first-child) {
                    border-left: 1px solid #ebebeb;
                }

                .line-box {
                    min-width: 250px;
                    margin: 25px 25px 25px 0;
                    padding: 0 0 0 26px;
                    text-align: center;

                    .function-box {
                        display: flex;
                        justify-content: space-around;

                        .function-item {
                            width: 64px;
                            padding: 12px;
                            border: 1px solid #eeeeee;
                        }

                        .function-item-icon {
                            padding: 18px 8px;
                        }

                        .function-item-title {
                            text-align: center;
                        }
                    }

                    p {
                        font-size: 18px;
                        // color: #838383;
                        color: #fff;
                        margin-bottom: 22px;
                    }

                    .phone-number {
                        font-size: 22px;
                        // color: #242424;
                        color: #ffffff;
                        font-weight: bold;
                    }

                    .tips-line {
                        // color: #838383;
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }

            .el-image {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;
                background-color: #ffffff;
            }
        }

        .footer-bot {
            background: #333333;
            color: #9d9d9d;

            .footer-bot-wrapper {
                width: $width;
                margin: 0 auto;
                display: flex;
                justify-content: space-around;
                padding: 50px;
                border-bottom: 1px solid #777777;

                div {
                    padding-left: 12px;
                }

                .item {
                    padding: 20px;
                    font-size: 28px;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .footer-fot {
            background: #333333;
            color: #9d9d9d;

            .footer-fot-wrapper {
                width: $width;
                display: flex;
                margin: 0 auto;
                justify-content: center;
            }
        }
    }
    .footer-mobile {
    }
</style>
